.filter-container {
    position: absolute;
    right: 0;

    &:after, &:before {
        bottom: 100%;
        right: 8px;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    
    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
        border-width: 8px;
        margin-left: -8px;
    }

    &:before {
        border-color: rgba(204, 204, 204, 0);
        border-bottom-color: #eee;
        border-width: 9px;
        margin-right: -1px;
    }

    .gr-form-element {
        margin-bottom: 1rem;
    }    

    .actions {
        justify-content: center;
        margin: 2rem -1rem 0 -1rem;
        border-top: 1px solid #E6E6E6;
        padding: 1rem 0 0;
    }
}

/* Inline Filters - Additional filters */
.additional-filter-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 8px;
    position: absolute;
    z-index: 15;
    width: 100%;
    right: 0;
    top: 88%;
    background: $color-theme-surfaces-light-surface-active;
    border-radius: 8px;
    border: 1px solid $color-theme-transparent-light-56;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(20px);

    .MuiChip-root {
        .MuiSvgIcon-root {
            display: none;
            color: #020917;
        }
        &.selected {
            background-color: $color-theme-ref-tertiary-tertiary95;

            .MuiSvgIcon-root {
                display: block;
            }
        }
    }
}


.selected-filters {
    padding: 6px 0 16px;
    
    .MuiChip-root {
        .MuiSvgIcon-root {
            display: none;
            color: #020917;
        }
        &.selected {
            background-color: $color-theme-ref-tertiary-tertiary95;

            .MuiSvgIcon-root {
                display: block;
            }
        }
    }
}
    
.multiselect__selections, .selected-filters {
    display: flex;
    width: 100%;    
    flex-wrap: wrap;
    align-items: center;

    .label {
        margin-right: 16px;
        font-weight: bold;
        font-size: 12px;
    }

    .chip {
        padding: 4px 8px;
        background: #CFD8DC;
        margin-right: 8px;
        margin-bottom: 5px;
        border-radius: 2px;
        display: inline-flex;
        align-items: center;
        font-size: 11px;
        line-height: 1.4;
        color: #263238;
        border-radius: 30px;

        .custom-close {
            margin: 1px 0 0 5px;
        }
    }
}

.filter-checks {
    display: flex;
    justify-content: space-between;
}

.checkbox-container {
    display: flex;

    .gr-form-element {

        label {
            white-space: nowrap;
            display: inline-flex;
            align-items: center;
        }

        img {
            margin-right: 4px;
            vertical-align: middle;
        }
    }
}