
/* Assignment Review */
.review-list {
    border-top: 1px solid #E4E7EB;
    padding: 8px 16px;
    background-color: #fafafa;
    
}
.review-item {
    border: 0;
    width: 100%;
    text-align: left;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(89, 96, 100, 0.2), 0px 2px 4px rgba(89, 96, 100, 0.2);
    border-radius: 8px;
    margin: 8px 0 16px;
    height: 55px;
    display: grid;
    grid-template-columns: 24px 32px auto auto 100px 48px 36px;
    align-items: center;
    border: 1px solid #fff;
    padding: 4px 8px;
    box-sizing: border-box;

    &.selected {
        background: #F2FCFF;
        border-color: #047A9C;
        box-shadow: 0px 1px 2px rgba(89, 96, 100, 0.2), 0px 2px 4px rgba(89, 96, 100, 0.2);
    }

    &:focus, &:active {
        outline: none;
        box-shadow: 0px 1px 2px rgba(89, 96, 100, 0.2), 0px 2px 4px rgba(89, 96, 100, 0.2);
    }

    .gr-input {
        margin: 0;
        display: inline-block;
        max-width: 40px;
        height: 24px;
        padding: 0 5px;
        margin-right: 5px;
    }

    a {
        text-decoration: none;
    }
}

@keyframes review {
    0% {flex-basis: 5%; flex-grow: 0; width: 5%;}
    100%{flex-basis: 100%; flex-grow: 1; width: 100%;}
}

@keyframes highlightBg {
    0% {background-color: rgba(255, 243, 133, 1);}
    100% {background-color: rgba(255, 243, 133, 0);}
}