.section-list {
    h4 {
        margin: 0;
        padding-left: 18px;
        border-bottom: 1px solid #E4E7EB;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            h5 {
                margin: 0;
                font-size: 14px;
                font-weight: 400;
                padding: 0 18px;
                background: #FFFFFF;
                border-bottom: 1px solid #c2c7cc;

                &:hover {
                    background: rgba(231, 232, 236, 0.5);
                }

                .sortableColumn {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    font-size: 14px;

                    &:focus, &:active {
                        outline: none;
                        box-shadow: none;
                    }

                    em {
                        font-style: normal;
                        color: #616E7C;
                        font-size: 12px;
                    }
                }
            }

            &.open {
                h5 {
                    background-color: #F2FCFF;

                    .sortableColumn {
                        .circle {
                            background-color: #0189B0;
                        }

                        svg {
                            fill: #fff;
                        }
                    }
                }
            }

            .question-table {
                border-collapse: collapse;
                width: 100%;

                th {
                    background: linear-gradient(180deg, #F2F3F7 0%, rgba(230, 231, 236, 0) 12.57%), #F7F8FC;
                    border-bottom: 1px solid #CBD2D9;
                    padding: 0 18px;
                }

                td {
                    padding: 0 18px;
                    border-bottom: 1px solid #CBD2D9;
                    vertical-align: middle;
                    height: 64px;
                    
                    .difficulty {
                        display: inline-block;
                        margin: 0 auto;
                    }
                }

                th, td {
                    &.text-center {
                        text-align: center;

                        .sortableColumn {
                            margin: 0 auto;
                        }
                    }
                }
                .add-item {
                    width: 40px;
                    height: 40px;
                    margin: 0;
                    border-radius: 50%;

                    svg {
                        border-radius: 50%;
                        border: 2px solid #007FA3;
                        fill: #007FA3;
                    }

                    &:focus, &:active {
                        box-shadow: none;
                        outline: 0;
                    }

                    &:hover {
                        background-color: rgba(0, 127, 163, 0.08);

                        svg {
                            fill: #fff;
                            background-color: #007FA3;
                        }
                    }

                    &.selected {
                        position: relative;

                        &::before {
                            content: '';
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background-color: #4caf50;
                            position: absolute;
                            top: 0;
                            right: 0;
                        }
                    }
                }

                .gr-checkbox {
                    margin: 0;
                    
                    input[type=checkbox] {
                        z-index: 2;    
                        width: 24px;
                        height: 24px;
        
                        ~ label {
                            position: absolute;
                            left: -9999px;
                            top: -9999px;        
                        }
                    }
                }

                a {
                    text-decoration: none;
                }
            }
        }
    }
}