.assignment-selections {
    border-radius: 0.5rem;
    grid-column: 2;
    width: 300px;
    background-color: white;

    header {
        display: flex;
        justify-content: space-between;
        grid-gap: 0;
        border-radius: 0.5rem 0.5rem 0 0;
        align-items: center;
        padding: 4px 4px 0 4px;
        background: $gradient-theme-surfaces-gradient-secondary-light-1, $gradient-theme-surfaces-gradient-secondary-light-0;

        * {
            color: $color-theme-sys-light-on-primary;
            fill: $color-theme-sys-light-on-primary;
        }
        h3 {
            margin: 0;
        }
    }

    .initial-text {
        text-align: center;
        max-width: 250px;
        margin: 70px auto;

        p {
            color: #616E7C;
            font-size: 14px;
        }
    }

    ul.items-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        flex: 1;

        > li {
            .item-inner {
                border-radius: 0.25rem;
                display: grid;
                grid-template-columns: 50px auto 40px;
                align-items: center;
                padding: 0.5rem;    
                --lh: 1.4rem;
                line-height: var(--lh);
                grid-column-gap: 0.25rem;

                &.pool-top {
                    margin-bottom: 0;
                    border-radius: 6px 6px 0 0;
                }

                a {
                    --max-lines: 2;
                    max-height: calc(var(--lh) * var(--max-lines));
                    overflow: hidden;
                    font-size: 13px;
                    text-decoration: none;
                    display: block;
                }

                .pool-toggle {
                    background-color: transparent;
                    border: 0;
                    grid-column: 4;

                    .circle {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        width: 24px;
                        height: 24px;
                    }

                    &:hover {
                        .circle {
                            background-color: rgba(217, 217, 217, 0.65);
                        }
                    }
                }
            }

            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
                border-radius: 0 0 6px 6px;

                li {
                    .item-inner {
                        display: grid;
                        grid-template-columns: 50px auto 40px;
                        box-shadow: none;
                        border-radius: 0;
                        align-items: center;
                        padding: 0;      
                        margin: 0;    
                        --lh: 1.4rem;
                        line-height: var(--lh);
                        grid-column-gap: 5px;                       
                        border-bottom: 1px solid $color-theme-sys-light-outline-variant;

                        a {
                            --max-lines: 2;
                            max-height: calc(var(--lh) * var(--max-lines));
                            overflow: hidden;
                            font-size: 13px;
                            text-decoration: none;
                            display: block;
                        }
                    }

                    &:last-child {
                        .item-inner {
                            border-radius: 0 0 6px 6px;
                        }
                    }
                }
            }

            &.pool {
                border-radius: 6px;
                margin-bottom: 10px; 

                > .item-inner {
                    border-radius: 6px;
                }

                &.expanded {
                    border: 2px solid #047A9C;

                    > .item-inner {
                        background-color: #F2FCFF;
                        border-bottom: 1px solid #047A9C;

                        .circle {
                            background-color: #007FA3;

                            svg {
                                fill: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

    .selection-summary {        
        padding: 16px;
        display: flex;
        justify-content: flex-start;
        border-bottom: 1px solid $color-theme-sys-light-outline-variant; 

        > div {
            padding: 0 20px;
            border-right: 1px solid $color-theme-sys-light-outline-variant;;
            white-space: nowrap;

            &:last-child {
                text-align: right;
                border: 0;
                padding-right: 0;
            }

            &:first-child {
                padding-left: 0;
            }
        }
    }
}