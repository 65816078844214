
.options-menu {
    position: relative; 

    .gr-dropdown-container {
        .option-text {
            display: -ms-inline-flexbox;
            display: inline-flex;
            margin: 4px 16px;
            width: 100%; 
        }

        .option-text.has-icon {
            margin-left: 40px !important;
        }
    }

    .gr-btn {
        width: 38px;
        height: 38px;
    }

    .dropdown-menu {
        top:calc(100% + 8px);
        right: 9px; 
    }

    .menu {
        top: 0;
        overflow:auto;
    }
}