.add-content-body {
    display: flex;
    gap: 1.5rem;
  
    .assignment-creation-content {
        max-width: calc(100% - 300px);
    }

    &.preview-closed {
        gap: 0; 

        .assignment-creation-content {
            flex: 1 0 100%;
            margin-right: 0;
            max-width: 100%;
        }

        .assignment-selections {
            border: 0;
            overflow: hidden;
        }
    }

    &.in-review {
        .assignment-creation-content {
            overflow: hidden;
            border: 0;
            flex: 0 0 0;
        }

        .review-content {
            height: calc(100vh - 325px);
            animation: review 400ms ease-out; 
            flex-shrink: 0;
            animation-iteration-count: 1;
            animation-fill-mode: both;

            header {
                padding: 0;
                margin: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex: 0 0 57px;

                .gr-btn {
                    &.secondary {
                        svg {
                            fill: #047A9C;
                            
                            &:last-child {
                                margin: 0 0 0 10px;
                            }
            
                            &:first-child {
                                margin: 0 10px 0 0;
                            }
                        }
                    }
                }
            }

            .review-table-actions {
                flex: 0 0 46px;
                border-bottom: 1px solid #E4E7EB;

                .selection-summary { 
                    display: flex;
                    justify-content: space-between;
            
                    > div {
                        flex: 1;
                        padding: 0 20px;
                        border-right: 1px solid #9AA5B1;
                        white-space: nowrap;
            
                        &:last-child {
                            text-align: right;
                            border: 0;
                            padding-right: 0;
                        }
            
                        &:first-child {
                            padding-left: 0;
                        }
                    }
                }
            }

            footer {
                background: linear-gradient(0deg, #FAFAFA, #FAFAFA);
                box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
                padding: 16px;
                display: flex;
                justify-content: space-between;
                flex: 0 0 50px;                
            }
        }
    }

    .empty-state {
        margin: 4rem auto 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-content: center;
        text-align: center;
        padding-bottom: 100px;
        color: $color-theme-text-lighttheme-muted;
        font: $font-typography-body-body-medium;
    }
}





.table-header {
    align-items: center;
    display: flex;
    white-space: nowrap;
}