.assignment-metadata {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.assignment-row {
    cursor: pointer;

    &:hover {
        background: $color-theme-surfaces-light-surface-hover;
    }
}

span.difficulty-label {
    color: $color-theme-text-lighttheme-muted;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1; /* 100% */
    letter-spacing: 0.15px;
    display: block;
}