.sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;

    .header-logo {
        margin-bottom: 3rem;
    }

    .main-menu {
        .MuiMenuItem-root {
            padding: 0;
            border-radius: 50%;
            max-height: 3rem;
            margin: 1.5rem 0;
        }

        .MuiLink-root {
            border-radius: 50%;
            width: 3rem;
            height: 3rem;
            text-indent: -9999px;
            background-color: rgba(2, 9, 23, 0.32);
        }

        .Mui-selected {
            .MuiLink-root {
                background-color: $color-theme-sys-light-primary-container;
            }
        }
    }
}