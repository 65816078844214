:root {
  --color-theme-white: #ffffffff;
  --color-theme-black: #000000ff;
  --color-theme-indigo: #1d3557ff;
  --color-theme-text-lighttheme-default: #020917ff;
  --color-theme-text-lighttheme-muted: #585858ff;
  --color-theme-text-darktheme-default: #fefefeff;
  --color-theme-text-darktheme-muted: #b5b5b5ff;
  --color-theme-sys-light-primary: #007e53ff;
  --color-theme-sys-light-on-primary: #ffffffff;
  --color-theme-sys-light-primary-container: #5bdda0ff;
  --color-theme-sys-light-on-primary-container: #005234ff;
  --color-theme-sys-light-primary-on-surface: #006c46ff;
  --color-theme-sys-light-secondary: #5066c5ff;
  --color-theme-sys-light-on-secondary: #ffffffff;
  --color-theme-sys-light-secondary-container: #b9c3ffff;
  --color-theme-sys-light-on-secondary-container: #293e98ff;
  --color-theme-sys-light-secondary-on-surface: #4357b2ff;
  --color-theme-sys-light-tertiary: #00809bff;
  --color-theme-sys-light-on-tertiary: #ffffffff;
  --color-theme-sys-light-tertiary-container: #5ad5f9ff;
  --color-theme-sys-light-on-tertiary-container: #004e5fff;
  --color-theme-sys-light-tertiary-on-surface: #00677dff;
  --color-theme-sys-light-error: #af2e30ff;
  --color-theme-sys-light-on-error: #ffffffff;
  --color-theme-sys-light-error-container: #ffb3aeff;
  --color-theme-sys-light-on-error-container: #8d131bff;
  --color-theme-sys-light-error-on-surface: #af2e30ff;
  --color-theme-sys-light-success: #008859ff;
  --color-theme-sys-light-on-success: #ffffffff;
  --color-theme-sys-light-success-container: #5bdda0ff;
  --color-theme-sys-light-on-success-container: #005234ff;
  --color-theme-sys-light-success-on-surface: #006c46ff;
  --color-theme-sys-light-warning: #857100ff;
  --color-theme-sys-light-on-warning: #ffffffff;
  --color-theme-sys-light-warning-container: #fee264ff;
  --color-theme-sys-light-on-warning-container: #534600ff;
  --color-theme-sys-light-warning-on-surface: #6e5d00ff;
  --color-theme-sys-light-safety: #d56818ff;
  --color-theme-sys-light-on-safety: #ffffffff;
  --color-theme-sys-light-safety-container: #ffaf77ff;
  --color-theme-sys-light-on-safety-container: #773a0eff;
  --color-theme-sys-light-safety-on-surface: #a65113ff;
  --color-theme-sys-light-info: #00809bff;
  --color-theme-sys-light-on-info: #ffffffff;
  --color-theme-sys-light-info-container: #5ad5f9ff;
  --color-theme-sys-light-on-info-container: #004e5fff;
  --color-theme-sys-light-info-on-surface: #00677dff;
  --color-theme-sys-light-outline: #757874ff;
  --color-theme-sys-light-background: #ffffffff;
  --color-theme-sys-light-on-background: #191c1aff;
  --color-theme-sys-light-surface: #eeeeedff;
  --color-theme-sys-light-on-surface: #191c1aff;
  --color-theme-sys-light-surface-variant: #e1e3dfff;
  --color-theme-sys-light-on-surface-variant: #444845ff;
  --color-theme-sys-light-inverse-surface: #2e312eff;
  --color-theme-sys-light-inverse-on-surface: #eff1edff;
  --color-theme-sys-light-inverse-primary: #5bdda0ff;
  --color-theme-sys-light-inverse-secondary: #b9c3ffff;
  --color-theme-sys-light-inverse-tertiary: #5ad5f9ff;
  --color-theme-sys-light-shadow: #000000ff;
  --color-theme-sys-light-surface-tint: #006c46ff;
  --color-theme-sys-light-outline-variant: #c0c9c1ff;
  --color-theme-sys-light-scrim: #000000ff;
  --color-theme-sys-light-focus-a11y: #266eb5ff;
  --color-theme-sys-dark-primary: #5bdda0ff;
  --color-theme-sys-dark-on-primary: #003822ff;
  --color-theme-sys-dark-primary-container: #006c46ff;
  --color-theme-sys-dark-on-primary-container: #7afabaff;
  --color-theme-sys-dark-primary-on-surface: #3ac186ff;
  --color-theme-sys-dark-secondary: #94a6ffff;
  --color-theme-sys-dark-on-secondary: #092582ff;
  --color-theme-sys-dark-secondary-container: #4357b2ff;
  --color-theme-sys-dark-on-secondary-container: #dde1ffff;
  --color-theme-sys-dark-secondary-on-surface: #94a6ffff;
  --color-theme-sys-dark-tertiary: #5ad5f9ff;
  --color-theme-sys-dark-on-tertiary: #003642ff;
  --color-theme-sys-dark-tertiary-container: #00677dff;
  --color-theme-sys-dark-on-tertiary-container: #b3ebffff;
  --color-theme-sys-dark-tertiary-on-surface: #34b9dcff;
  --color-theme-sys-dark-error: #ffb3aeff;
  --color-theme-sys-dark-on-error: #68000cff;
  --color-theme-sys-dark-error-container: #8d131bff;
  --color-theme-sys-dark-on-error-container: #ffdad7ff;
  --color-theme-sys-dark-error-on-surface: #ff8983ff;
  --color-theme-sys-dark-success: #5bdda0ff;
  --color-theme-sys-dark-on-success: #003822ff;
  --color-theme-sys-dark-success-container: #006c46ff;
  --color-theme-sys-dark-on-success-container: #7afabaff;
  --color-theme-sys-dark-success-on-surface: #3ac186ff;
  --color-theme-sys-dark-warning: #e1c64bff;
  --color-theme-sys-dark-on-warning: #3a3000ff;
  --color-theme-sys-dark-warning-container: #6e5d00ff;
  --color-theme-sys-dark-on-warning-container: #fee264ff;
  --color-theme-sys-dark-warning-on-surface: #c4aa32ff;
  --color-theme-sys-dark-safety: #ffaf77ff;
  --color-theme-sys-dark-on-safety: #4c2a12ff;
  --color-theme-sys-dark-safety-container: #773a0eff;
  --color-theme-sys-dark-on-safety-container: #ffc69eff;
  --color-theme-sys-dark-safety-on-surface: #ff9850ff;
  --color-theme-sys-dark-info: #5ad5f9ff;
  --color-theme-sys-dark-on-info: #003642ff;
  --color-theme-sys-dark-info-container: #00677dff;
  --color-theme-sys-dark-on-info-container: #b3ebffff;
  --color-theme-sys-dark-info-on-surface: #34b9dcff;
  --color-theme-sys-dark-outline: #8f918eff;
  --color-theme-sys-dark-background: #000000ff;
  --color-theme-sys-dark-on-background: #e1e3dfff;
  --color-theme-sys-dark-surface: #191c1aff;
  --color-theme-sys-dark-on-surface: #c5c7c3ff;
  --color-theme-sys-dark-surface-variant: #444845ff;
  --color-theme-sys-dark-on-surface-variant: #c5c7c3ff;
  --color-theme-sys-dark-inverse-surface: #e1e3dfff;
  --color-theme-sys-dark-inverse-on-surface: #191c1aff;
  --color-theme-sys-dark-inverse-primary: #006c46ff;
  --color-theme-sys-dark-inverse-secondary: #4357b2ff;
  --color-theme-sys-dark-inverse-tertiary: #00677dff;
  --color-theme-sys-dark-shadow: #000000ff;
  --color-theme-sys-dark-surface-tint: #5bdda0ff;
  --color-theme-sys-dark-outline-variant: #404943ff;
  --color-theme-sys-dark-scrim: #000000ff;
  --color-theme-sys-dark-focus-a11y: #4fa8ffff;
}

.rst__rowWrapper {
  padding: 0;
  height: 100%;
  box-sizing: border-box;
}

.rst__rowWrapper a {
  text-decoration: none;
}

.rst__rowWrapper.pool-top, 
.rst__rowWrapper.pool-top .rst__rowContents, 
.rst__rowWrapper.pool-top .rst__moveHandle, 
.rst__rowWrapper.pool-top .rst__row {
  position: relative;
}

/*.rst__rowWrapper.pool-top:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f5f5f5;
  z-index: 1;
} */
.rst__tree .MuiPaper-root {
  border-radius: 0;
}
.rst__nodeContent .type .icon-container {
  background-color: #5066C5;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rst__nodeContent .type .icon-container svg {
  color: #fff;
}

.rst__node .MuiPaper-root.pool-top {
  border: 1px solid #C0C9C1;
  border-width: 1px 1px 0 1px;
  border-radius: 8px 8px 0 0;
}

.rst__node .MuiPaper-root.pool-top.collapsed {
  border-radius: 8px;
}

.rst__node .MuiPaper-root.pool-child {
  border: 1px solid #C0C9C1;
  border-width: 0 1px 0 1px;
}
.rst__node .MuiPaper-root.pool-child.last-child {
  border-radius: 0 0 8px 8px;
  border-width: 0 1px 1px 1px;
} 
.rst__node .MuiPaper-root.pool-child.last-child .rst__row {
  border: 0;
}

.rst__rowWrapper.pool-top .rst__row {
  grid-template-columns: 38px 1fr;
}

.rst__rowWrapper.pool-top .rst__row .rst__rowContents .rst__toolbarButton .item-menu {
  display: none;
}

.rst__rowWrapper.pool-top .rst__row.selected .rst__rowContents .pool-name svg,
.rst__rowWrapper.pool-top .rst__row.selected .rst__rowContents .pool-toggle svg,
.rst__rowWrapper .rst__row.selected .rst__rowContents .rst__toolbarButton .item-menu svg
{
  color: #fff;
}
.rst__rowWrapper.pool-top .rst__row .pool-toggle {
  margin-right: 24px;
}

.rst__rowWrapper.pool-child .rst__row {
  grid-template-columns: 38px 1fr;
  border-radius: 0;
}

.rst__rowWrapper.pool-top .rst__rowContents .favorite, 
.rst__rowWrapper.pool-top .rst__rowContents .time,
.rst__rowWrapper.pool-top .rst__rowContents .objective {
  display: none;
}

.rst__rowWrapper.pool-top .rst__rowContents .pool-dropdown {
  display: block;
}

.rst__rowWrapper.pool-top .pool-name {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
  display: flex;
  align-items: center;
  gap: 4px;
  grid-column: 1 / span 8;
}

.rst__rowWrapper.pool-top .pool-name span {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 14.4px */
  letter-spacing: 0.72px;
  display: inline-block;
  margin-left: 24px;
}

.rst__rowWrapper.pool-child {
  margin: 0;
  border-radius: 0;
}

.rst__rowWrapper.pool-child .rst__rowContents .points-fld {
  display: none;
}

.rst__rowWrapper.pool-child .rst__row,
.rst__rowWrapper.pool-top .rst__row {
  grid-template-columns: 52px 1fr;
}

.rst__rowWrapper.pool-child .rst__moveHandle,
.rst__rowWrapper.pool-top .rst__moveHandle {  
  border-radius: 4px 0 0 4px;
}

.rst__rtl.rst__rowWrapper {
  padding: 10px 0 10px 10px;
}

.rst__row {
  height: 100%;
  white-space: nowrap;
  display: grid !important;
  grid-template-columns: 60px 1fr !important;
  grid-column-gap: 12px;
  cursor: pointer;
  /* box-shadow: 0px 1px 2px rgba(89, 96, 100, 0.2), 0px 2px 4px rgba(89, 96, 100, 0.2);
  background-color: #fff;
  border-radius: 6px;*/
  border-bottom: 1px solid #C0C9C1;
}

.rst__row:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.rst__row.selected {
    background: var(--color-theme-sys-light-secondary);
    border-bottom: 1px solid #C0C9C1;
}

.rst__row.parent-selected {
  background: #FEFEFEF5;
}

.rst__row.selected .rst__rowContents, .rst__row.selected .rst__rowContents .options-menu svg {
  color: #FFFFFF;
}

.rst__row.selected .rst__rowContents .type .icon-container {
  background: #fff;
}

.rst__row.selected .rst__rowContents .type .icon-container svg {
  color: #5066C5;
}

.pool-top .rst__row.expanded {
  border-width: 2px 2px 1px 2px;
}
.rst__row > * {
  box-sizing: border-box;
}

.rst__rowContents,
.rst__rowLabel,
.rst__rowToolbar,
.rst__moveHandle,
.rst__toolbarButton {
  display: inline-block;
  vertical-align: middle;
}

.rst__toolbarButton:last-child {
  text-align: right;
  margin-right: 24px;
}

.rst__rowContents {
  position: relative;
  height: 100%;
  padding: 0;
  min-width: 230px;
  align-items: center;
  justify-content: space-between;
  display: grid !important;
  grid-template-columns: repeat(12, 1fr) !important;
  grid-column-gap: 12px;
  color: #585858;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%; /* 20.02px */
  letter-spacing: 0.252px;
  padding: 16px 0 15px;
}

.pool-top .rst__rowContents {
  padding: 3px 0;
}

.rst__rowContents .rst__rowLabel {
  grid-column: 2 / span 7;
  white-space: pre-wrap;
  max-height: 48px;
}
.rst__rowContents .objective {
  grid-column: 4 / span 4;
  white-space: no-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rst__rowContents .pool-dropdown {
  grid-column: 4 / span 5;
  display: none;
}

.rst__rowContents .points-fld input {
  border: 1px solid #C7C7C7;
  width: 40px;
  height: 24px;
  border-radius: 4px;
  background-color: #fff;
  padding: 5px;
  text-align: center;
  margin-right: 10px;
} 

.pool-top .rst__rowContents .rst__rowToolbar,
.pool-child .rst__rowContents .rst__rowToolbar {
  /* padding-left: 8px; */
}

.pool-top .rst__rowContents .selections .gr-checkbox,
.pool-child .rst__rowContents .selections .gr-checkbox {
  margin: 0;
}

.rst__rtl.rst__rowContents {
  border-right: none;
  border-left: 1px solid #e7e8ec;
  padding: 0 10px 0 5px;
}

.rst__rowContentsDragDisabled {
  border-left: 1px solid #e7e8ec;
}

.rst__rtl.rst__rowContentsDragDisabled {
  border-right: 1px solid #e7e8ec;
  border-left: 1px solid #e7e8ec;
}

.rst__rowLabel {
  flex: 0 1 auto;
}
.rst__rtl.rst__rowLabel {
  padding-left: 20px;
  padding-right: inherit;
}

.rst__rowToolbar {
  grid-column: 9 / span 4;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr !important;
  align-items: center;
  gap: 16px;
}

.pool-top .rst__rowToolbar .rst__toolbarButton:has(.more-btn) {
  display: none;
}

.pool-top .rst__rowContents .rst__rowLabel {
  display: none;
}

.pool-top .rst__toolbarButton .difficulty {
  min-width: 88px;
}

.pool-top .rst__rowToolbar .pool-toggle {
  grid-column: 4;
  grid-row: 1;
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pool-top .rst__rowToolbar .pool-toggle .circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pool-top .rst__rowToolbar .pool-toggle .circle svg {
  fill: #6a7070;
}

.rst__moveHandle,
.rst__loadingHandle {
  height: 100%;
  width: 24px;
  margin-left: 16px;
  cursor: move;
  z-index: 1;
  display: flex;
  align-items: center;
}

.rst__moveHandle .circle {
  border-radius: 50%;
  padding: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.rst__moveHandle:hover .circle {
  background-color: #02091714;
}

.rst__row.selected .rst__moveHandle svg {
  color: #fff;
}

.rst__loadingHandle {
  cursor: default;
  background: #d9d9d9;
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.rst__loadingCircle {
  width: 80%;
  height: 80%;
  margin: 10%;
  position: relative;
}

.rst__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.rst__rtl.rst__loadingCirclePoint {
  right: 0;
  left: initial;
}

.rst__loadingCirclePoint::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 11%;
  height: 30%;
  background-color: #fff;
  border-radius: 30%;
  animation: pointFade 800ms infinite ease-in-out both;
}
.rst__loadingCirclePoint:nth-of-type(1) {
  transform: rotate(0deg);
}
.rst__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg);
}
.rst__loadingCirclePoint:nth-of-type(1)::before,
.rst__loadingCirclePoint:nth-of-type(7)::before {
  animation-delay: -800ms;
}
.rst__loadingCirclePoint:nth-of-type(2) {
  transform: rotate(30deg);
}
.rst__loadingCirclePoint:nth-of-type(8) {
  transform: rotate(210deg);
}
.rst__loadingCirclePoint:nth-of-type(2)::before,
.rst__loadingCirclePoint:nth-of-type(8)::before {
  animation-delay: -666ms;
}
.rst__loadingCirclePoint:nth-of-type(3) {
  transform: rotate(60deg);
}
.rst__loadingCirclePoint:nth-of-type(9) {
  transform: rotate(240deg);
}
.rst__loadingCirclePoint:nth-of-type(3)::before,
.rst__loadingCirclePoint:nth-of-type(9)::before {
  animation-delay: -533ms;
}
.rst__loadingCirclePoint:nth-of-type(4) {
  transform: rotate(90deg);
}
.rst__loadingCirclePoint:nth-of-type(10) {
  transform: rotate(270deg);
}
.rst__loadingCirclePoint:nth-of-type(4)::before,
.rst__loadingCirclePoint:nth-of-type(10)::before {
  animation-delay: -400ms;
}
.rst__loadingCirclePoint:nth-of-type(5) {
  transform: rotate(120deg);
}
.rst__loadingCirclePoint:nth-of-type(11) {
  transform: rotate(300deg);
}
.rst__loadingCirclePoint:nth-of-type(5)::before,
.rst__loadingCirclePoint:nth-of-type(11)::before {
  animation-delay: -266ms;
}
.rst__loadingCirclePoint:nth-of-type(6) {
  transform: rotate(150deg);
}
.rst__loadingCirclePoint:nth-of-type(12) {
  transform: rotate(330deg);
}
.rst__loadingCirclePoint:nth-of-type(6)::before,
.rst__loadingCirclePoint:nth-of-type(12)::before {
  animation-delay: -133ms;
}
.rst__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg);
}
.rst__loadingCirclePoint:nth-of-type(13) {
  transform: rotate(360deg);
}
.rst__loadingCirclePoint:nth-of-type(7)::before,
.rst__loadingCirclePoint:nth-of-type(13)::before {
  animation-delay: 0ms;
}

.rst__rowTitle .itemId {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%; /* 22.88px */
  letter-spacing: 0.15px;
  margin-right: 24px;
}

.rst__rowTitle .item-details div {
  margin-top: 8px;
}

.rst__rowTitleWithSubtitle {
  font-size: 85%;
  display: block;
  height: 0.8rem;
}

.rst__rowSubtitle {
  font-size: 70%;
  line-height: 1;
}

.rst__collapseButton,
.rst__expandButton {
  appearance: none;
  border: none;  
  border-radius: 100%;
  padding: 0;
  cursor: pointer;
  z-index: 10;
  width: 44px;
  height: 44px;
}

.rst__collapseButton .icon-18,
.rst__expandButton .icon-18 {
  width: 8px;
  height: 8px;
}


/**
 * Line for under a node with children
 */
.rst__lineChildren {
  height: 100%;
  display: inline-block;
  position: absolute;
  display: none;
}
.rst__lineChildren::after {
  content: '';
  position: absolute;
  background-color: black;
  width: 1px;
  left: 50%;
  bottom: 0;
  height: 10px;
}

.rst__rtl.rst__lineChildren::after {
  right: 50%;
  left: initial;
}

.difficulty svg {
  color: #8F918E;
}

.difficulty svg .green {
  fill: var(--color-theme-sys-light-success);
}
.rst__row.selected .difficulty svg .green {fill: var(--color-theme-sys-dark-success);
}

.difficulty svg .yellow {
  fill: var(--color-theme-sys-light-warning);
}
.rst__row.selected .difficulty svg .yellow {
  fill: var(--color-theme-sys-dark-warning);
}

.difficulty svg .red {
  fill: var(--color-theme-sys-light-error);
}
.rst__row.selected .difficulty svg .red {
  fill: var(--color-theme-sys-dark-error);
}

.difficulty svg .blue {
  fill: var(--color-theme-sys-light-info);
}
.rst__row.selected .difficulty svg .blue {
  fill: var(--color-theme-sys-dark-info);
}

.rst__row.selected .difficulty svg {
  color: #fff;
}