/* Switch/Toggle */
.switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
  
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $color-theme-ref-neutral-neutral70 url(../images/icon_cross.png) no-repeat 28px center;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;

        &:before {
            position: absolute;
            content: "";
            height: 18px;
            width: 18px;
            top: 3px;
            left: 4px;
            bottom: 3px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 50%;
        }
    }

    input { 
        opacity: 0;
        width: 0;
        height: 0;
    
        &:checked {
            + .slider {
                background: $color-theme-sys-light-secondary url(../images/icon_check.png) no-repeat 9px center;

                &:before {
                    -webkit-transform: translateX(24px);
                    -ms-transform: translateX(24px);
                    transform: translateX(24px);
                }
            }
        }
        
        &:focus { 
            + .slider {
                box-shadow: 0 0 1px #047A9C;
            }
        }

        &:disabled, &[disabled] {
            + .slider {
                background-color: #C2C7CC;
            }
        }
    }
}