.main-content {
    padding: 1rem 2.25rem;
}
.dashboard-2col {
    display: flex;
    gap: 3.25rem;

    .col1 {
        flex: 0 0 37.5rem;
    }

    .col2 {
        flex: 1;
    }
} 

.page-title {
    color: #020917;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */ 
}

.wrapper {
    h3 {
        font-family: 'inter';
        font-size: 2.25rem;
        font-weight: 300;
        line-height: 1.5;
        margin: 0;
        padding: 0;
    }

    h4 { 
        margin: 0;
    }
}

.due-date {
    border: 0;
    background-color: $color-theme-sys-light-tertiary;
    border-radius: 4px;
    color: $color-theme-sys-light-on-tertiary;
    text-transform: uppercase;
    width: 2.375rem;
    height: 2.375rem;
    font-size: 0.75rem;
    font-weight: 600;

    .MuiSvgIcon-root {
        color: $color-theme-sys-light-on-tertiary;
    }
}

.table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.book-details {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.piechart-label {
    position: absolute;
    top: 45%;
    left: 66px;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;

    span {
        display: block;
        font-size: 0.75rem;
        letter-spacing: 6%;
    }
}

/* MUI override */
.MuiCard-root {
    &.table-wrapper {
        &:hover {
            background: $gradient-theme-surfaces-light-gradient-master !important;
        }
    }
}